import React, {useState, useEffect} from 'react'
import { PictureUrl } from '../components/include';
import Template from '../components/template';

const ExchangeRate =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});


    return (<Template 
    title='Customer Dashboard'>
    

    <div class="col-lg-7 col-md-7 col-xs-12">
																<div class="inner-box my-resume">
																	
																				
																<div class="work-experence item">
																<h3 style={{color: '#d51224', fontWeight:'bold'}}>Foreign Exchange Rates</h3>
																
																<div class="table-responsive">
                                            <table class="table">
                                      <thead>
                                        <tr>
                                            <th style={{color: '#00b2aa'}}>Currency</th>
                                            <th class="hidden-xs" style={{color: '#00b2aa'}}>Cheques</th>
                                            <th style={{color: '#00b2aa'}}>Electronic Transfers</th>
                                            <th style={{color: '#00b2aa'}}>Notes</th>
                                            
                                        </tr> 
                                      </thead>
                                      </table>
                                      
                                      <div class="table-responsive">
                                            <table class="table">
                                      <thead>
                                        <tr>
                                            <th style={{color: '#00b2aa'}}>Description</th>
                                            <th class="hidden-xs" style={{color: '#00b2aa'}}>Code</th>
                                            <th style={{color: '#00b2aa'}}>Buying</th>

                                            <th style={{color: '#00b2aa'}}>Buying</th>
                                            <th style={{color: '#00b2aa'}}>Selling</th>
                                            <th style={{color: '#00b2aa'}}>Buying</th>
                                            <th style={{color: '#00b2aa'}}>Selling</th>
                                            
                                        </tr> 
                                      </thead>
                                    <tbody>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>U.A.E. DIRHAM</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>AED</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >0.223000</td>
                                              <td height="20" >0.240000</td>
                                            </tr>
                                              <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>AUSTRALIAN DOLLAR</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>AUD</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>1.592500</td>
                                              <td height="20" >1.576900</td>
                                              <td height="20" >1.54110</td>
                                              <td height="20" >0.617000</td>
                                              <td height="20" >0.665000</td>
                                            </tr>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>CANADIAN DOLLAR</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>CAD</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>1.544000</td>
                                              <td height="20" >1.528900</td>
                                              <td height="20" >1.494100</td>
                                              <td height="20" >0.636000</td>
                                              <td height="20" >0.687000</td>
                                            </tr>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>SWISS FRANC</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>CHF</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>1.172600</td>
                                              <td height="20" >1.170900</td>
                                              <td height="20" >1.159200</td>
                                              <td height="20" >0.835000</td>
                                              <td height="20" >0.883000</td>
                                            </tr>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>CZECH KORUNA</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>CZK</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >0.038000</td>
                                              <td height="20" >0.040000</td>
                                            </tr>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>DANISH KORUNA</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>DKK</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>7.612600</td>
                                              <td height="20" >7.538100</td>
                                              <td height="20" >7.359300</td>
                                              <td height="20" >0.129000</td>
                                              <td height="20" >0.1390000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>EURO</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>EUR</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>1.000000</td>
                                              <td height="20" >1.000000</td>
                                              <td height="20" >1.000000</td>
                                              <td height="20" >1.000000</td>
                                              <td height="20" >1.000000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>POUND STERLING</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>GBP</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>0.882600</td>
                                              <td height="20" >0.880400</td>
                                              <td height="20" >0.873400</td>
                                              <td height="20" >1.115000</td>
                                              <td height="20" >1.165000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>HONG KONG DOLLAR</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>HKD</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >0.104000</td>
                                              <td height="20" >0.112000</td>
                                            </tr>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>HUNGARIAN FORINT</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>HUF</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >0.003000</td>
                                              <td height="20" >0.003000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>ISRAELI SHEKEL</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>ILS</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >0.229000</td>
                                              <td height="20" >0.248000</td>
                                            </tr>
                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>JORDAN DINAR</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>JOD</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >1.152000</td>
                                              <td height="20" >1.243000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>JAPANESE YEN</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>JPY</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>130.837500</td>
                                              <td height="20" >130.64350</td>
                                              <td height="20" >129.342600</td>
                                              <td height="20" >0.008000</td>
                                              <td height="20" >0.008000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>NORWEGIAN KRN</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>NOK</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>9.707000</td>
                                              <td height="20" >9.612000</td>
                                              <td height="20" >9.388800</td>
                                              <td height="20" >0.101000</td>
                                              <td height="20" >0.109000</td>
                                            </tr>

                                            <tr height="20">
                                              
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>NEW ZELAND DOLLAR</td>
                                              <td class="hidden-xs" height="20" style={{color: '#00b2aa'}}>NZD</td>
                                              
                                              <td height="20" style={{color: '#00b2aa'}}>NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >NA</td>
                                              <td height="20" >0.564000</td>
                                              <td height="20" >0.608000</td>
                                            </tr>










                                          </tbody>
                                        </table>
                                      </div>
                                      
                                      
                                     
                                     
																							
																</div>
													



														</div>
														</div>
														</div>

       

</Template>
);
       
}

export default React.memo(ExchangeRate)  
