import React from 'react'

import Swal from 'sweetalert2'
import { store } from 'react-notifications-component';
import { adminImages } from './include';


export const  LoadingModal =()=>{
  // use warning, error, success, info, and question as icon
  // use 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end' as position
  Swal.fire({
    allowOutsideClick:false,
    imageUrl: adminImages+"/loading.gif",
    
    
    showConfirmButton:false,
  imageAlt: 'Loading',
    title: 'Loading, Please wait...',
  })  
  
} 

export const  FetchingModal =()=>{
  // use warning, error, success, info, and question as icon
  // use 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end' as position
  Swal.fire({
    width: 100,
    allowOutsideClick:false,
    imageUrl: adminImages+"/loader.gif", 
    showConfirmButton:false,
  imageAlt: 'Loading',
    title: '',
  })  
  
} 

export const closeModal =()=>{
  Swal.close()
}


export const  ErrorModal =(title, text)=>{
  
  Swal.fire({
    icon: 'error',
    width: 500,
    allowOutsideClick:true,
    allowEscapeKey:true,
    allowEnterKey:true,
    showConfirmButton:true,
    confirmButtonColor: '#3085d6',
    confirmButtonText:'Retry',
    title: title,
    text: text
  })
  
} 

export const  Alerts =(title, type, content)=>{
  // 'default', 'success', 'info', 'warning', danger
  store.addNotification({
    title: title,
    message: content,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true
    }
  })
  
}



export const InfoModal = (props) =>{
  return      <div className="modal fade " id={`infoDialog-${props.mID}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title"><i className='fa fa-info fa-2x'></i> Wait!</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">
                <p>{props.title}</p>
              </div>
              <div className="modal-footer">
                  <button type="button"  id={`btnInfoDialog-${props.mID}`} className="btn btn-default waves-effect " data-dismiss="modal">No, Cancel</button>
                  <button type="button" onClick={props.handleConfirm} className="btn btn-outline-warning waves-effect">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>
}

export const WarningModal = props =>{
  return      <div className="modal fade " id={`warningdialog-${props.mID}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
              <div className="modal-body">
                <div className="text-center"><i className="icon-shield-notice icon-5x text-warning"></i>
                  <h5>Hold down!</h5>
                <p>Once deleted, you will no be able to recover this record. Are you realy sure?</p>
              </div></div>
              <div className="modal-footer">
                  <button type="button"  id={`btnWarningDialog-${props.mID}`} className="btn btn-primary " data-dismiss="modal">No, Thanks</button>
                  <button type="button" onClick={props.handleAction} className="btn btn-warning ">Yes I Am</button>
              </div>
          </div>
      </div>
  </div>
}

export const LabelWarning =(props)=> <div className="alert alert-warning alert-styled-left alert-dismissible">
<button type="button" className="close" data-dismiss="alert"><span>×</span></button>
<span className="font-weight-semibold">Warning!</span> {props.message}
</div>

export const LabelSuccess =(props)=> <div className="alert alert-success alert-styled-left alert-dismissible">
<button type="button" className="close" data-dismiss="alert"><span>×</span></button>
<span className="font-weight-semibold">Weldone!</span> {props.message}
</div>

export const SessionTimeout = (props) =>{
  return   <div class="modal fade " id='timeoutModal'  aria-modal="true">
  
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <h5 class="modal-title">Session Timeout</h5>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button></div>
        <div class="modal-body">Your session is about to expire. Do you want to stay connected?</div><div class="modal-footer">
          <button id="timeOut" type="button" onClick={props.Connected} class="btn btn-primary" data-dismiss="modal">Stay connected</button>
          
          <button id="logout" type="button" onClick={props.Logout} class="btn btn-danger">Logout</button>
          </div>
          
          </div></div>
          
          </div>

}

 
export default Alerts