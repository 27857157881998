import React, {useState, useEffect} from 'react'

import {  config, ServerUrl, customerCode, PictureUrl, FrontEndUrl } from './include';
import { Alerts } from './notify';
import axios from 'axios'
import Cookies from 'js-cookie'
import { longDate } from './globalFunction';

const Sidebar = (props)=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    
 
    const [users, setUsers] = useState([])
    const fetchContent =()=>{
        if(customerCode!=='null'){
    
        var sql ="Select passport, 	fullname from tbl_user where code ='"+customerCode+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                setUsers(result.data[0])
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
         
        })
        
        }
    }
    
    
    
    useEffect(()=>{
       fetchContent() 
        },[]);

    return  <div className="col-lg-5 col-md-5 col-xs-12">
    <div className="right-sideabr">
    <h4>Manage Account</h4>
        <ul className="list-item">
            <li><a className="active" href="/home">Client Home</a></li>
            <li><a href="/customer/edit_profile">Lookup Customer</a></li>
            <li><a href="/customer/transfers">International Transfers</a></li>
            <li><a href="/customer/local">Local Transfers</a></li>
            <li><a href="#!">Bill Payment Status</a></li>
            <li><a href='/'>Sign Out</a></li>
        </ul>
</div>




<br />

<div id="gcw_mainFhAdXO9Qv" className="gcw_mainFhAdXO9Qv"><div className="gcw_headerFhAdXO9Qv">   
 <a href="https://freecurrencyrates.com/en/myconverter#cur=USD-EUR-GBP-JPY-CNY-RUB;src=eucb;amt=USD1">Currency Converter</a></div>

<div className="gcw_ratesFhAdXO9Qv">    
<table className="gcw_tableFhAdXO9Qv">
    <tbody>
        <tr className="gcw_itemFhAdXO9Qv">
            <td className="gcw_flag_tdFhAdXO9Qv">
                <img id="gcw_flagFhAdXO9Qv0" className="gcw_flagFhAdXO9Qv" src="https://freecurrencyrates.com/flags/24/us.png" title="United States dollar" />
                    </td>
                    <td className="gcw_ttlFhAdXO9Qv" title="United States dollar">       <b>USD</b>    </td>    <td className="gcw_valblockFhAdXO9Qv">    
                    <input className="gcw_inputFhAdXO9Qv" id="gcw_valFhAdXO9Qv0" type="text" title="United States dollar" value="1" rate="1" onKeyUp="gcw_handlerFhAdXO9Qv.update(0)" onchange="gcw_handlerFhAdXO9Qv.update(0,1)" />    
                    </td></tr>
                    
                    <tr className="gcw_itemFhAdXO9Qv"><td className="gcw_flag_tdFhAdXO9Qv"><img id="gcw_flagFhAdXO9Qv1" className="gcw_flagFhAdXO9Qv" src="https://freecurrencyrates.com/flags/24/eu.png" title="Euro" /></td>
                    <td className="gcw_ttlFhAdXO9Qv" title="Euro">       <b>EUR</b>    </td>   
                    
                     <td className="gcw_valblockFhAdXO9Qv" >   
                     <input className="gcw_inputFhAdXO9Qv" id="gcw_valFhAdXO9Qv1" type="text" title="Euro" value="0.82" rate="0.81980652565994" onKeyUp="gcw_handlerFhAdXO9Qv.update(1)" onchange="gcw_handlerFhAdXO9Qv.update(1,1)" />   

                      </td></tr><tr className="gcw_itemFhAdXO9Qv">
                          <td className="gcw_flag_tdFhAdXO9Qv">
                              <img id="gcw_flagFhAdXO9Qv2" className="gcw_flagFhAdXO9Qv" src="https://freecurrencyrates.com/flags/24/gb.png" title="British pound" />
                              </td>
                              <td className="gcw_ttlFhAdXO9Qv" title="British pound">       <b>GBP</b>    </td>    <td className="gcw_valblockFhAdXO9Qv">    
                              <input className="gcw_inputFhAdXO9Qv" id="gcw_valFhAdXO9Qv2" type="text" title="British pound" value="0.71" rate="0.705591080505" onKeyUp="gcw_handlerFhAdXO9Qv.update(2)" onchange="gcw_handlerFhAdXO9Qv.update(2,1)" />    </td></tr>
                              
                              <tr className="gcw_itemFhAdXO9Qv"><td className="gcw_flag_tdFhAdXO9Qv"><img id="gcw_flagFhAdXO9Qv3" className="gcw_flagFhAdXO9Qv" src="https://freecurrencyrates.com/flags/24/jp.png" title="Japanese yen" />
                                  </td>
                                  <td className="gcw_ttlFhAdXO9Qv" title="Japanese yen" >       <b>JPY</b>    </td>   
                                   <td className="gcw_valblockFhAdXO9Qv">    
                                   <input className="gcw_inputFhAdXO9Qv" id="gcw_valFhAdXO9Qv3" type="text" title="Japanese yen" value="109.38" rate="109.37858665355" onKeyUp="gcw_handlerFhAdXO9Qv.update(3)" onchange="gcw_handlerFhAdXO9Qv.update(3,1)" />    
                                   </td></tr>
                                   
                                   <tr className="gcw_itemFhAdXO9Qv"><td className="gcw_flag_tdFhAdXO9Qv"><img id="gcw_flagFhAdXO9Qv4" className="gcw_flagFhAdXO9Qv" src="https://freecurrencyrates.com/flags/24/cn.png" title="Chinese yuan" /></td>
                                   
                                   <td className="gcw_ttlFhAdXO9Qv" title="Chinese yuan">       <b>CNY</b>    </td>    <td className="gcw_valblockFhAdXO9Qv">    <input className="gcw_inputFhAdXO9Qv" id="gcw_valFhAdXO9Qv4" type="text" title="Chinese yuan" value="6.38" rate="6.3787506148549" onKeyUp="gcw_handlerFhAdXO9Qv.update(4)" onchange="gcw_handlerFhAdXO9Qv.update(4,1)" />   
                                    </td></tr><tr className="gcw_itemFhAdXO9Qv"><td className="gcw_flag_tdFhAdXO9Qv"><img id="gcw_flagFhAdXO9Qv5" className="gcw_flagFhAdXO9Qv" src="https://freecurrencyrates.com/flags/24/ru.png" title="Russian ruble" />
                                    </td>
                                    <td className="gcw_ttlFhAdXO9Qv" title="Russian ruble">       <b>RUB</b>    </td>    <td className="gcw_valblockFhAdXO9Qv">   
                                     <input className="gcw_inputFhAdXO9Qv" id="gcw_valFhAdXO9Qv5" type="text" title="Russian ruble" value="73.40" rate="73.402197081489" onKeyUp="gcw_handlerFhAdXO9Qv.update(5)" onchange="gcw_handlerFhAdXO9Qv.update(5,1)" />    </td></tr>        </tbody></table>
                                     </div>
                                     
                                     
                                     <div className="gcw_sourceFhAdXO9Qv">    <a href="https://freecurrencyrates.com/en/get-widget" className="gcw_infoFhAdXO9Qv" title="www.freecurrencyrates.com widgets">        <div className="gcw_info-bgFhAdXO9Qv"></div>        <div className="gcw_info-signFhAdXO9Qv"></div></a>    <a href="javascript:void(0)" id="gcw_refreshFhAdXO9Qv" onclick="gcw_handlerFhAdXO9Qv.reload()" title="Refresh">{longDate(new Date())}</a></div>
                                     
                                     </div>

<br/><br/>

                                     <div className="container">
  <div className="bar-chart-block block">
    <div className="grafico bar-chart">
       <ul className="eje-y">
         <li data-ejey="15"></li>
         <li data-ejey="45"></li>
         <li data-ejey="30"></li>
         <li data-ejey="15"></li>
         <li data-ejey="0"></li>
       </ul>
       <ul className="eje-x">
         <li data-ejex="37"><i>España</i></li>
         <li data-ejex="15"><i>USA</i></li>
         <li data-ejex="25"><i>Italia</i></li>
         <li data-ejex="18"><i>Grecia</i></li>
         <li data-ejex="45"><i>EE.UU</i></li>
         <li data-ejex="50"><i>Canada</i></li>
         <li data-ejex="33"><i>Chile</i></li>
       </ul>
    </div>
  </div>
            </div>

</div>
    
}
export default React.memo(Sidebar)