import React, {useState, useEffect} from 'react'
import Sidebar from './sidebar';
import Footer from './footer';
import { PictureUrl } from './include';
import Topbar from './topbar';
import { authenticate } from './authentication';


const Template =(props)=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    

    useEffect(()=>{
       authenticate()
    })
    return (<>


<Topbar />

<div className="page-header" style={{background:`url(${PictureUrl+"/banner1.jpg"})`}}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                    <div className="inner-header">
                    <h3 style= {{color: '#fff'}}>{props.title}</h3>
                    </div>
                    </div>
                    </div>
                </div>
									</div>



                    <div className="section">
                                                                        
                            <div className="container">
                                        <div className="row">
                                                     
                                                                                                
    <Sidebar />
    

{props.children}

        </div>
        </div>
        </div>


<Footer />

</>
);
       
}

export default React.memo(Template)  
