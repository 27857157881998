import React, {useState, useEffect} from 'react'

import {  config, ServerUrl, customerCode, PictureUrl, FrontEndUrl } from './include';
import { Alerts } from './notify';
import axios from 'axios'
import Cookies from 'js-cookie'

const Topbar = ()=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    
    
const [users, setUsers] = useState([])
const fetchContent =()=>{
    if(customerCode!=='null'){

    var sql ="Select passport, 	fullname from tbl_user where code ='"+customerCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller.php'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
            setUsers(result.data[0])
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    
    }
}



useEffect(()=>{
   fetchContent() 
    },[]);


    return   <header id="home" className="hero-area">
    
    <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar">
    <div className="container">
    <div className="theme-header clearfix">
    
                                <div className="navbar-header">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                            <span className="lni-menu"></span>
                                            <span className="lni-menu"></span>
                                            <span className="lni-menu"></span>
                                    </button>
                                    <a href="home" className="navbar-brand"><img src={PictureUrl+"/rlogo.png"} alt="" /></a>
                                </div>
    
                <div className="collapse navbar-collapse" id="main-navbar">
                                            <ul className="navbar-nav mr-auto w-100 justify-content-end">
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/home" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Client Area
            </a>

            </li>
            <li className="nav-item dropdown active">
            <a className="nav-link dropdown-toggle" href="#!" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Cards
            </a>

            </li>
            
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/customer/transfers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Transfer
            </a>
        
            </li>
            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/customer/exchange_rates" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Exchange
            </a>

            </li>
            <li className="nav-item">
            <a className="nav-link" href="/customer/previous">
            Previous Transactions
            </a>
            </li>
            
            <li className="button-group">
            <a href='/' className="button btn btn-common">Sign Out</a>
            </li>
                </ul>
                </div>
                </div>
                </div>
                <div className="mobile-menu" data-logo={ PictureUrl+"/rlogo.png"}></div>
                </nav>
    
    </header>
    
}
export default React.memo(Topbar)