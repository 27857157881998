import React, {useState, useEffect} from 'react'
import { PictureUrl } from '../components/include';
import Template from '../components/template';

const DailyTransfer =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});


    return (<Template 
    title='Daily Transfer'>
    

    <div class="col-lg-7 col-md-7 col-xs-12">
                                    <div class="inner-box my-resume">
                                        
                                                    
                                    <div class="work-experence item">
                                    <h3 style={{color: '#d51224', fontWeight:'bold'}}>Daily Transaction History</h3>
                                    
                                    <div class="table-responsive">
                <table class="table">
            <thead>
            <tr>
                <th style={{color: '#00b2aa'}}>Date</th>
                <th class="hidden-xs" style={{color: '#00b2aa'}}>Debited account</th>
                
                
                <th style={{color: '#00b2aa'}}>Beneficiary</th>
                <th style={{color: '#00b2aa'}}>Amount</th>
                <th style={{color: '#00b2aa'}}>Status</th>
            </tr> 
            </thead>
            <tbody>
                                        
                                                            </tbody>
                </table>
                                                                    
                                        </div>
                            



                                </div>
                                </div>
                                </div>


       

</Template>
);
       
}

export default React.memo(DailyTransfer)  
