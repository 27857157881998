import React, {useState, useEffect} from 'react'

import { customerCode, PictureUrl, ServerUrl, config, adminImages } from '../components/include';
import Template from '../components/template';

import { Alerts } from '../components/notify';
import axios from 'axios'
import { FormatNumber } from '../components/globalFunction';

const Previous =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

      
const [transaction, setTransaction] = useState([])
const fetchContent =()=>{

    var sql ="Select * from tbl_transaction where customerCode ='"+customerCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
   // fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller.php'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
            setTransaction(result.data)
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        
    })
}


useEffect(()=>{
    fetchContent() 
      },[]);

    return (<Template 
    title='Customer Dashboard'>
    
    <div class="col-lg-7 col-md-7 col-xs-12">
																<div class="inner-box my-resume">
																	
																				
																<div class="work-experence item">
																<h3 style={{color: '#d51224', fontWeight:'bold'}}>Previous Transactions</h3>
																
																<div cl="" ass="table-responsive">
                                            <table class="table">
                                      <thead>
                                        <tr>
                                            <th style={{color: '#00b2aa'}}>Date</th>
                                            <th class="hidden-xs" style={{color: '#00b2aa'}}>Amount</th>
                                            <th style={{color: '#00b2aa'}}>Description</th>
                                            <th style={{color: '#00b2aa'}}>Transaction</th>
                                            <th style={{color: '#00b2aa'}}>Ref</th>
                                        </tr> 
                                      </thead>
                                      
                                      <tbody>
                                          
                                       {transaction&&transaction.map((data, id)=>
                                           <tr key={id}>
                                              
                                              <td class="hidden-xs">{data.dateCreate}</td>
                                              <td class="hidden-xs"> {FormatNumber(data.amount)}</td>
                                              <td style={{color: '#d51224'}}>{data.description}</td>
                                              <td><span class={data.transactionType==='Withdrawal'? "part-time":data.transactionType==='Deposit'?'full-time':'full-time'}>({data.transactionMode})</span>
                                                  </td>
                                              <td> {data.code}</td>
                                            </tr>)}

                                           
                                          
                                          </tbody>
                                       
                                        </table>
																							
																</div>
													



														</div>
														</div>
														</div>

       

</Template>
);
       
}

export default React.memo(Previous)  
